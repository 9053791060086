<template>
  <div>
    <div style="height: calc(100vh - 20vh - 90px);" class="flex-column" v-loading="loading">
      <div class="flex-row margin-bottom-20 border-bottom padding-bottom-10">
          <div>配送方式：{{info.psfs}}</div>
          <template v-if="(info.yf_info||{}).code==0">
            <div class="margin-left-20 padding-left-20 border-left">运费：￥{{info.yf_info.data.total_yf}}</div>
            <div class="color-sub">（
              <span v-for="(item,index) in info.yf_info.data.info" :key="index">{{ item.feeTypeName }}：￥{{ item.money }}{{index!=info.yf_info.data.info.length-1?'，':''}}</span>
            ）</div>
          </template>
      </div>
      <div class="flex-column" v-if="(info.wz_info||{}).code==0&&(info.wz_info||{}).data&&(info.wz_info||{}).data.length" style="flex:0 0 60%"><wuliuMap :points="(info.wz_info||{}).data"></wuliuMap></div>
      <div class="flex-auto padding-top-10" style="overflow: auto;">
        <el-timeline v-if="(info.gj_info||{}).code==0">
          <el-timeline-item
            v-for="(item, index) in info.gj_info.data"
            :key="index"
            :timestamp="item.sj">
            {{item.info}}
          </el-timeline-item>
        </el-timeline>
        <div v-else>{{(info.gj_info||{}).msg}}</div>
      </div>
    </div>
  </div>
</template>

<script>

import wuliuMap from "@/components/wuliuMap";
export default {
  name: 'wuliu',
  components: {wuliuMap},
  props:{
    orderId:{default:''},
  },
  data() {
    return {
      loading:false,
      info:{}
    }
  },
  computed:{
    
  },
  methods:{
    getWuliu(){
      this.loading=true
      let params= {
        ...this.$store.state.basicParams,
        order_id:this.orderId,
        belonger_user_id:this.$store.state.currCustomer.user_id||0,
      }
      this.$instance.get('/order/get_order_wl_info',{params})
      .then(res=>{
        this.loading=false
        if(res.data.code==0){
          this.info=res.data.data
         
        }
      })
      .catch(()=> {
        this.loading=false
      }); 
    }
  },
  created(){
    this.getWuliu()
  },
  mounted(){

  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>

</style>
