<template>
  <div>
    <div class="display-inline-block">
      <div class="margin-bottom-10 flex-row align-items-center justify-between">
        <div class="bold"><span v-if="mhj" class="color-red">*</span>签名</div>
        <el-button type="mini" @click="clearSign" round>重新签名</el-button>
      </div>
      <div class="sign-area margin-bottom-20">
        <div style="position:absolute;width:100%;height:100%;top:0;left:0;" class="flex-row">
          <div class="flex-row justify-center align-items-center" style="flex:1;border-right: #aaa dotted 1px; font-size: 80px; color: #f0f0f0;">请</div>
          <div class="flex-row justify-center align-items-center" style="flex:1;border-right: #aaa dotted 1px; font-size: 80px; color: #f0f0f0;">签</div>
          <div class="flex-row justify-center align-items-center" style="flex:1; font-size: 80px; color: #f0f0f0;">名</div>
        </div>
        <vue-esign style="position:relative" :width="600" :height="200" ref="esign"></vue-esign>
      </div>
    </div>
    <div class="margin-bottom-10 bold">收货人职务</div>
    <el-radio-group v-model="identity">
      <el-radio label="企业负责人">企业负责人</el-radio>
      <el-radio label="店长">店长</el-radio>
      <el-radio label="营业员">营业员</el-radio>
    </el-radio-group>
    <div class="margin-top-20 margin-bottom-10 bold">上传图片</div>
    <el-upload 
      ref="upload" 
      list-type="picture-card"
      :limit="10" 
      accept=".jpg,.gif,.png,.jpeg" 
      :multiple="true"
      action=""
      :on-change="handleFileChange"
      :before-remove="handleFileRemove" 
      :on-exceed="exceed"
      :auto-upload="false"
      >
        <i slot="default" class="el-icon-plus"></i>
    </el-upload>
    <div class="margin-top-20 margin-bottom-10 bold">回执留言</div>
    <el-input v-model="message"></el-input>
    <div class="flex-row justify-end margin-top-10">
      <el-button type="primary" round :loading="uploadLoading" @click="handleSignature">提交回执</el-button>
    </div>
    
  </div>
</template>

<script>

export default {
  name: 'Sign',
  components: {},
  props:{
    orderId:{default:''},
    mhj:{default:false}
  },
  data() {
    return {
      uploadLoading:false,
      fileList:[],
      signImg:'',
      message:'',
      identity:'',
    }
  },
  methods:{
    handleSignature() {
      // 处理签字数据
      this.$refs.esign.generate().then(res => {
        this.signImg = res
        this.uploadFile()
      }).catch(() => {
        if(this.mhj){
           this.$message({message: '没有签名信息',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
        }else{
          this.uploadFile()
        }
       
      })
    },
    //清空签名
    clearSign () {
      this.$refs.esign.reset()
      this.signImg=''
    },
    // 上传发生变化钩子
    handleFileChange(file, fileList) {
      this.fileList = fileList;
    },
    // 删除之前钩子
    handleFileRemove(file, fileList) {
      this.fileList = fileList;
    },
    clearFiles(){
      this.$refs.upload.clearFiles()
      this.fileList=[]
    },
    exceed(){
      this.$message({message: '只能上传10张图片，请先删除已选文件',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
    },
   
    uploadFile(){
      if(!this.identity){
        this.$message({message: '请选择收货人职务',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
        return false
      }
      this.uploadLoading=true
       // 创建新的数据对象
       let formData = new FormData();
      // 将上传的文件放到数据对象中
      this.fileList.forEach(file => {
        formData.append('order_img', file.raw);
      });
      // formData.append('order_img',this.fileList.map(v=>v.raw));
      formData.append('order_no',this.orderId)
      formData.append('sign_img_str',this.signImg)
      formData.append('receipt_msg',this.message)
      formData.append('zhiwu',this.identity)
      // console.log("提交前",formData.getAll('order_img'));
      this.$instance.post('/order/post_order_receipt',formData,
        {
          timeout: 300000,
        } 
      ).then(res=>{
        this.uploadLoading=false
        if(res.data.code==0){
          this.$message({message: res.data.msg,type:'success',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)});
          this.successCallBack(res.data.data)
        }
      })
      .catch(()=>{
        this.uploadLoading=false
      })
    },
    successCallBack(res){
      if(res.hzd_img_url){
        this.$alert('确认签收成功?', '签收成功', {
          customClass:"custom-msgbox",
          dangerouslyUseHTMLString:true,
          message:`<div style='text-align:center'><img src='${res.hzd_img_url}' style='max-width:300px;max-height:300px' /></div>`,
          showClose:false,
          showCancelButton:false,
          confirmButtonText: '确定',
          type: 'success'
        })
        .then(() => {
          this.$emit('success',this.orderId)
        })
        .catch(() => {});
      }else{
        this.$emit('success',this.orderId)
      }
      
    }
  },
  created(){
    
  },
  mounted(){

  },
  watch: {
   "orderId"(){
    this.$nextTick(()=>{
      this.clearSign()
      this.clearFiles()
      this.message=''
    })
   }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
.sign-area{
  position: relative;
  border: #aaa dotted 1px;
  width: 600px;
  height: 200px;
}
</style>
